(() => {
  function setViewportProperty() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh + 0.01}px`);
  }

  function resizeViewportProperty() {
    let lastInnerWidth = window.innerWidth;

    function updateInnerWidth() {
      const updateLastInnerWidth = lastInnerWidth === window.innerWidth;
      if (!updateLastInnerWidth) setViewportProperty();
      lastInnerWidth = window.innerWidth;
    }

    window.addEventListener('resize', updateInnerWidth);
  }

  setViewportProperty();
  resizeViewportProperty();
})();
