export default function setHeight() {

  //高さ計算
  // $(document).ready(function () {
  //   hsize = $(window).height(); //window高さをセット
  //   $(".topKv").css("height", hsize);
  // });

  const vh = window.innerHeight;
  document.getElementById('js-setHeight').style.height = vh + 'px';

}
