import gsap from "gsap";
import zoom from "swiper/src/components/zoom/zoom";

(() => {
    //targetになるelement
    const target = document.getElementById('loading');
    if (!target) return;

    //master timeline
    const master = gsap.timeline();

    //loadingが終わったあとにアニメーションさせる要素
    const loadingItems = [...document.querySelectorAll('[data-loading-item]')];
    //幕が上がったあとにアニメーションさせる要素
    const loadingAfter = [...document.querySelectorAll('[data-loading-after]')];

    //loadingAfterの初期設定
    loadingAfter.forEach((item) => {
      gsap.set(item, {
        autoAlpha: 0,
      });
    });

    //幕が上がる前に実行するアニメーション
    const before = () => {
      const tl = gsap.timeline();
      loadingItems.forEach((item) => {
        tl.to(item, {
          autoAlpha: 0,
          duration: .8,
          ease: 'Power4.easeOut'
        })
      })
      return tl;
    }

    //幕を上げる処理
    function slideUp() {
      const tl = gsap.timeline();
      tl.to(target, {
        y: target.clientHeight * -1,
        duration: .6,
        ease: 'Power4.easeInOut',
      }).set(target,{
        display: 'none'
      })
      return tl;
    }

    //幕が上がったあとにするアニメーション
    function after() {
      const tl = gsap.timeline();
      loadingAfter.forEach((item) => {
        tl.to(item, {
          autoAlpha: 1,
          duration: 1,
          ease: 'Power4.easeOut'
        }, ">-1")
      })
      return tl;
    }

    async function loadEvent() {
      //iOSのバグ対策に2秒待ってからアニメーションを開始する
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 2000)
      });

      //アニメーション本体の処理
      master
        .add(before())
        .add(slideUp())
        .add(after(), "+=.5")
    }

    window.addEventListener('load', loadEvent);
  }

)();
