import 'react-app-polyfill/ie11';
import "@babel/polyfill";
import "picturefill";
import objectFitImages from "object-fit-images";
import "intersection-observer";

/* Modules
==================== */
// ドロワー
import drawer from "./modules/drawer";
drawer();

// ページトップ
import pagetop from "./modules/pagetop";
pagetop();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// アコーディオン
import accordion from "./modules/accordion";
accordion();

// スクロールイベント
import scrollEvent from "./modules/scrollEvent";
scrollEvent();

// heightを画面サイズに合わせる
import setHeight from "./modules/setHeight";
setHeight();

import './modules/loading';
import './modules/SetViewportProperty';


/* Script
==================== */
objectFitImages();
